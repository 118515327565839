import {
  FILTERS,
  KEYS,
  VENDOR_LISTINGS_EXTRA_FILTERS,
  VENUE_LISTINGS_EXTRA_FILTERS,
} from "@/src/const";
import { BlaceV2API } from "@/src/service";
import { AppSearchFilterType, BlaceV2Type, ComponentType } from "@/src/type";
import { AuthType, VenueType } from "@/src/type/blaceV1";
import {
  RegionDisplayValues as BlaceV2RegionDisplayValues,
  Regions as BlaceV2Regions,
  ListingStatus,
  PriceDurationBE,
  PriceDurationFE,
  SearchType,
  SearchTypes,
} from "@/src/type/blaceV2";
import {
  ArrayHelper,
  ImageFromCDN,
  Log,
  SharedConfigManager,
  StringHelper,
  uniqueId,
} from "@/src/util";
import * as ActivityLogic from "./ActivityLogic";
import * as ImageLogic from "./ImageLogic";
import * as VendorLogic from "./VendorLogic";
import * as VenueLogic from "./VenueLogic";

export { FILTERS } from "@/src/const";

/**
 * Parse the location object into a string that represents the address of an search item
 * @param {BlaceV2Type.SearchType.SearchLocation} location - the location address from search
 * @param {boolean} fullAddress - not used
 * @returns {string}
 */
export function getAddressForDisplay(location: BlaceV2Type.SearchType.SearchLocation): string {
  if (!location) {
    return "";
  }
  return location?.formattedAddress?.replace(", United States", "").replace(", USA", "") ?? "";
}

/**
 * Formats the price that should be shown for the search item
 * @param {BlaceV2Type.SearchType.Search} searchItem
 * @returns {string}
 */
export function formatSearchPricing(searchItem: BlaceV2Type.SearchType.Search): string {
  if (searchItem.dataType === SearchTypes.Venue) {
    return VenueLogic.formatVenuePricing(searchItem);
  }

  if (searchItem.dataType === SearchTypes.Vendor) {
    return VendorLogic.formatVendorPricing(searchItem);
  }

  return "";
}

/**
 * Translate the categories into text for display
 * @param {string[]} categories - the categories for a venue or vendor
 * @returns {string}
 */
export function translateCategories(categories?: string[]): string[] {
  if (!Array.isArray(categories)) {
    return [];
  }

  return categories.map((cat) => StringHelper.reverseCamelCase(cat?.replaceAll("-", " ")) ?? "");
}

/**
 * Get a neighboorhood for the search item based on the location
 * @param {BlaceV2Type.SearchType.SearchLocation} location - the location address from search
 * @param {string[]} regions - a area that address are grouped into for search
 * @returns {string}
 */
export function getNeighboorhoodForDisplay(
  location: BlaceV2Type.SearchType.SearchLocation,
  regions?: string[],
  dataType?: BlaceV2Type.SearchType.SearchDataType,
): string {
  if (dataType === "vendor") {
    return VendorLogic.getVendorAddressForDisplay(regions);
  }

  if (location?.neighborhood && location?.city) {
    return `${location.neighborhood}, ${location.city}`;
  }

  if ((regions ?? []).length > 0 && Array.isArray(regions)) {
    switch (regions[0] ?? "default") {
      case BlaceV2Regions.NY:
        return BlaceV2RegionDisplayValues.NY;
      case BlaceV2Regions.LA:
        return BlaceV2RegionDisplayValues.LA;
    }
  }

  return "";
}

/**
 * Get a list of all geo points with on properties: slug, locations/latitude, locations/longitude, facts/isExclusive, dataType
 * @param {string} filter - a filter string for Azure Search
 * @returns {BlaceV2Type.AzureSearchQueryType.Request}
 */
export function getAllGeoPoints(filter?: string): BlaceV2Type.AzureSearchQueryType.Request {
  return {
    count: true,
    search: "",
    queryType: "full",
    searchMode: "all",
    filter,
    select: "title, slug, dataType, locations, facts, categories, images, regions, capacity, price",
    searchFields: "title, description, locations/city, locations/neighborhood, locations/district",
    top: 10000,
    orderby: "weight/primary desc",
  };
}

/**
 * A basic query that can return all results weight by the primary property
 * @param {number} perPage - the number of results per page that should return from search
 * @returns {BlaceV2Type.AzureSearchQueryType.Request}
 */
export function defaultQuery(
  perPage: number = 20,
  filter?: string,
  select?: string,
): BlaceV2Type.AzureSearchQueryType.Request {
  return {
    count: true,
    search: "",
    queryType: "full",
    searchMode: "all",
    filter,
    select:
      select ||
      "images/imageHash, images/order, title, dataType, description, locations/city, locations/neighborhood, locations/district, price, capacity, categories, facts/isExclusive, slug, regions",
    searchFields:
      "dataType, title, description, locations/city, locations/neighborhood, locations/district",
    top: perPage,
    orderby: "weight/primary desc",
    sessionId: uniqueId(),
  };
}

/**
 * Query autocomplete index to determine if search string matches and terms
 * @param {string} toSearch - a string to search to get a list of potential autocomplete answers
 * @returns {BlaceV2Type.AzureSearchAutoCompleteType.Request}
 */
export function defaultAutoComplete(
  toSearch: string,
): BlaceV2Type.AzureSearchAutoCompleteType.Request {
  return {
    autocompleteMode: "twoTerms",
    fuzzy: true,
    search: toSearch,
    suggesterName: "sg_title-description-location",
    searchFields: "title, locations/city, locations/neighborhood, locations/district",
  };
}

/**
 * Query suggestion index to determine if search string matches and terms
 * @param {string} toSearch - a string to search to get a list of potential suggestion answers
 * @returns {BlaceV2Type.AzureSearchSuggestionType.Request}
 */
export function defaultSuggestions(
  toSearch: string,
  filter?: string,
): BlaceV2Type.AzureSearchSuggestionType.Request {
  return {
    fuzzy: true,
    search: toSearch,
    filter,
    suggesterName: "sg_title-description-location",
    searchFields: "title, locations/city, locations/neighborhood, locations/district",
    select: "title, searchId",
  };
}

export const REGIONS = [BlaceV2Regions.NY, BlaceV2Regions.LA];

export function isRegion(toCheck: BlaceV2Regions) {
  return REGIONS.includes(toCheck);
}

export const DATA_TYPE = ["venue", "vendor"];

export function isDataType(toCheck: string) {
  return DATA_TYPE.includes(toCheck);
}

export function constructSearchTerm(queryType?: "full" | "simple", query?: string) {
  if (!query) {
    return "";
  }

  query = escapeSpecialCharacters(StringHelper.trim(query));

  const partialMatchQuery: string = query?.replaceAll(" ", "*");

  return queryType === "full" ? `${partialMatchQuery}* OR ${query}` : query;
}

export function escapeSpecialCharacters(inputString: string) {
  // @see https://learn.microsoft.com/en-us/azure/search/query-lucene-syntax#special-characters
  return (inputString ?? "").replace(/[-+\\&|!(){}[\]^"~*?:/]/g, "\\$&");
}

export function escapeStringForComparison(inputString: string) {
  return (inputString ?? "").replace("'", "''");
}

/**
 * Construct a filter expression for Search query
 * https://learn.microsoft.com/en-us/azure/search/search-query-odata-filter
 */

/**
 * Construct a filter statement based on a property
 *
 * @param {AppSearchFilterType.AppSearchFilter} filter - the data about the filter
 * @param {string} expression - the expression used to search a property
 * @param {Record<string, any>} data - the data from the filter selection panel
 * @returns {string}
 */

export function constructFilterItem(
  filter: AppSearchFilterType.AppSearchFilter,
  expression: string,
  data: any,
): string {
  if (!data?.[filter.dataKey]) {
    return "";
  }

  const propertyData = data?.[filter.dataKey];
  switch (filter?.type ?? "default") {
    case "multi-choice":
      const terms = propertyData.join("|");
      //make sure to set delimiter in search query
      //delimited = 3rd method parameter in search.in
      return `search.in(${expression},'${terms}','|')`;
    case "single-choice":
    case "slider":
      if (
        typeof propertyData === "boolean" ||
        propertyData === "true" ||
        propertyData === "false"
      ) {
        return `${expression} eq ${propertyData}`;
      } else if (typeof propertyData === "string") {
        return `${expression} eq '${escapeStringForComparison(propertyData)}'`;
      }
    case "capacity-slider":
      if (typeof propertyData !== "string") {
        return "";
      }
      const dataArray = propertyData.split("*");
      const min = +dataArray[0];
      const max = +dataArray[1];
      const { capacitySliderMin, capacitySliderMax } = filter;
      if (min === capacitySliderMin && max === capacitySliderMax) {
        return "";
      } else if (min !== capacitySliderMin && max !== capacitySliderMax) {
        return `${expression} ge ${min} and ${expression} le ${max}`;
      } else if (min === capacitySliderMin) {
        return `${expression} le ${max}`;
      } else if (max === capacitySliderMax) {
        return `${expression} ge ${min}`;
      }
    case "value-ge":
      if (typeof propertyData !== "string") {
        return "";
      }
      return `${expression} ge ${propertyData}`;
    case "date-ge":
      const dateGreaterEquals = new Date(Number(propertyData));
      if (Number.isNaN(dateGreaterEquals.getTime())) {
        return ""; // broken or unsupported data
      }
      return `${expression} ge ${dateGreaterEquals.getTime()}`;
    case "date-le":
      const dateLessEquals = new Date(Number(propertyData));
      if (Number.isNaN(dateLessEquals.getTime())) {
        return ""; // broken or unsupported data
      }
      return `${expression} le ${dateLessEquals.getTime()}`;
    case "callback":
      if (!filter.callback) {
        return ""; // callback wasn't defined
      }
      return filter.callback(expression, propertyData);
  }
}

/**
 * Centralizes the logic for appending filter expressions
 *
 * @param {AppSearchFilterType.AppSearchFilter} filter - The current filter configuration object
 * @param {string} filterItemExpression -  The constructed part of the filter for the current property. This is created using the constructFilterItem
 * @param {boolean} hasPreviousExpression -  Indicating whether there is an existing filter expression
 * @returns {string}
 */
function appendExpression(
  filter: AppSearchFilterType.AppSearchFilter,
  filterItemExpression: string,
  hasPreviousExpression: boolean,
): string {
  const prefix = hasPreviousExpression ? " and " : "";
  switch (filter.propertyType) {
    case "simple-array":
      return `${prefix}${filter.property}/any(${filter.property.substring(0, 1)}: ${filterItemExpression})`;
    case "complex-array":
      const rootComplexArray = filter.property.split("/")[0];
      const rootPropertyAbbrev = rootComplexArray.substring(0, 1);
      return `${prefix}${rootComplexArray}/any(${rootPropertyAbbrev}: ${filterItemExpression})`;
    default:
      return `${prefix}${filterItemExpression}`;
  }
}

/**
 * Validates filter values
 *
 * @param {unknown} value - any filter value
 * @returns {boolean}
 */
function isValidFilterValue(value: unknown): boolean {
  if (!value) {
    return false;
  }
  if (Array.isArray(value)) {
    return value.length > 0;
  }
  return typeof value === "object" || typeof value === "string";
}

/**
 * Handles the logic of generating the expression key for different propertyType values
 *
 * @param {AppSearchFilterType.AppSearchFilter} filter - The filter configuration for the property
 * @returns {string}
 */
function getExpressionKey(filter: AppSearchFilterType.AppSearchFilter): string {
  switch (filter.propertyType) {
    case "simple-array":
      return filter.property.substring(0, 1);
    case "complex-array":
      const splitComplexArray = filter.property.split("/");
      return `${splitComplexArray[0].substring(0, 1)}/${splitComplexArray[1]}`;
    default:
      return filter.property;
  }
}

/**
 * Constructs the filter with multiple properties based on filter data
 *
 * @param {Record<string, any>}                                 filterData    - the data from the filter selection panel
 * @param {Record<string, AppSearchFilterType.AppSearchFilter>} filtersConfig - the configuration for the given filters
 * @returns {string}
 */
export function constructFilter(
  filterData?: Record<string, any>,
  filtersConfig: Record<string, AppSearchFilterType.AppSearchFilter> = FILTERS,
): string {
  if (!filterData || !Object.keys(filterData).length) {
    return "";
  }

  let filterExpression = "";
  let hasPreviousExpression = false;

  for (const propertyKey of Object.keys(filterData)) {
    const filter = filtersConfig[propertyKey];
    if (!filter) {
      continue;
    }

    const dataKey = filter.dataKey;
    const filterValue = filterData[dataKey];
    if (!isValidFilterValue(filterValue)) {
      continue;
    }

    const filterItemExpression = constructFilterItem(filter, getExpressionKey(filter), filterData);
    if (filterItemExpression?.length > 0) {
      filterExpression += appendExpression(filter, filterItemExpression, hasPreviousExpression);
      hasPreviousExpression = true;
    }
  }

  return filterExpression;
}

/**
 * Construct query params for next navigation
 *
 * @param {string} searchId - the unique identifier of current search iteration
 * @param {Record<string, unknown>} filterData - the data from the filter selection panel
 * @returns
 */
export function constructQueryParams(
  searchId: string,
  filterData?: Record<string, unknown>,
): { name: string; value: string }[] {
  const toCheckFilterData = !filterData || !Object.keys(filterData)?.length ? {} : filterData;

  const arr = [];
  for (const propertyKey of Object.keys(FILTERS)) {
    const filter = FILTERS[propertyKey];
    const data = toCheckFilterData[filter.dataKey];

    if (Array.isArray(data)) {
      arr.push({
        name: propertyKey,
        value: data.join("*"),
      });
    } else {
      arr.push({
        name: propertyKey,
        value: !data ? "" : `${data}`.replace(" ", "+"),
      });
    }
  }

  arr.push({
    name: "searchId",
    value: searchId,
  });

  arr.push({
    name: "page",
    value: "1",
  });

  return arr;
}

/**
 * deconstruct query params for filter state
 *
 * @param {string} searchParams - the search params from the url as a string
 * @param {Record<string, AppSearchFilterType.AppSearchFilter>} filtersConfig - the configuration for the given filters
 * @returns {Record<string,any>}
 */
export function deconstructQueryParams( // TODO merge with deconstructQueryParamsKeyValue
  searchParams: string,
  filtersConfig?: Record<string, AppSearchFilterType.AppSearchFilter>,
): Record<string, any> {
  if (!searchParams || !filtersConfig) {
    return {};
  }

  const filterData: Record<string, any> = {};
  const values = new URLSearchParams(searchParams);
  for (const propertyKey of Object.keys(filtersConfig)) {
    const filter = filtersConfig[propertyKey];
    const data = values.get(filter.dataKey);
    if (data) {
      if (filter.type === "multi-choice") {
        filterData[filter.dataKey] = data.split("*");
      } else {
        filterData[filter.dataKey] = data.replace("+", " ");
      }
    }
  }

  return filterData;
}

/**
 * deconstruct query params for filter state
 *
 * @param {Record<string,string>} values - the search params from the url as a key value par
 * @returns
 */
export function deconstructQueryParamsKeyValue(values: any) {
  if (!values) {
    return {};
  }

  const filterData: Record<string, any> = {};
  for (const propertyKey of Object.keys(FILTERS)) {
    const filter = FILTERS[propertyKey];
    const data = values[filter.dataKey];
    if (data) {
      if (filter.type === "multi-choice") {
        filterData[filter.dataKey] = data.split("*");
      } else {
        filterData[filter.dataKey] = data.replace("+", " ");
      }
    }
  }

  return filterData;
}

/**
 * add the delimiter for a multi-choice
 *
 * @param {string[] | number[]} data - any array of strings of numbers to be serialized as a multi-choice selection
 * @returns {string}
 */
export function constructQueryParamForMultiChoice(data: string[] | number[]): string {
  return (data ?? []).length > 0 ? data.join("*") : "";
}

/**
 * returns listings by provided slugs
 *
 * @param {string[]} slugs - slugs to search listing by
 * @param {string} sessionId - the unique identifier of the current session
 * @returns {BlaceV2Type.SearchType.SearchItem[]}
 */
export async function getListingBySlugs(
  slugs: string[],
  sessionId?: string,
): Promise<BlaceV2Type.SearchType.Search[]> {
  if (!slugs || !slugs.length) {
    return [];
  }

  const query = defaultQuery(slugs.length);
  query.search = "";
  query.queryType = "simple";
  query.filter = `search.in(slug,'${slugs.join("|")}','|')`;
  query.skip = 0;
  query.sessionId = sessionId || "";
  query.orderby = "";
  const listingResponse = await BlaceV2API.SearchServiceV2.postSearchQuery(query);

  const listings = listingResponse?.body?.payload.value;

  if (listingResponse?.success !== true || !listings || (listings ?? []).length !== slugs.length) {
    Log.logToDataDog(
      Log.LogLevel.ERROR,
      "SearchLogic.ts",
      "No or missing results",
      [slugs, listingResponse],
      "Not all listing slugs were found.",
    );

    return listings ?? [];
  }

  return listings;
}

/**
 * Formats SearchItem images to CarouselImages which are used at details page
 *
 * @param {BlaceV2Type.SearchType.Search} searchItem
 * @returns {ComponentType.Carousel.ImageType[]}
 */
export function formatCarouselImages(
  searchItem: BlaceV2Type.SearchType.Search,
): ComponentType.Carousel.ImageType[] {
  if (!searchItem.images?.length) {
    return [];
  }

  const images: BlaceV2Type.SearchType.SearchImage[] = ArrayHelper.arraySortByKey(
    searchItem.images,
    "order",
  );

  return images.map(
    (img, i): ComponentType.Carousel.ImageType => ({
      url: ImageFromCDN.imageSizeAndQuality(
        ImageLogic.ensureImageExtension(img.imageHash || ""),
        80,
        800,
        true,
      ),
      id: img.imageHash ?? `${i}`,
      alt: `${searchItem.title} ${searchItem.dataType} Image ${i}`,
    }),
  );
}

/**
 * Converts the V1 floors value to the V2 numeric one
 *
 * @param {string | undefined} floorValue - the value in format "Single Floor", "Multi Level", etc.
 * @returns {string | undefined}
 */
export function legacyFloorsToV2Number(floorValue?: string): string | undefined {
  if (!floorValue) {
    return undefined;
  }

  if ((+floorValue).toString() === floorValue) {
    return floorValue;
  }

  switch (floorValue) {
    case "Single Floor":
      return "1";
    case "Two Floors":
      return "2";
    case "Three Floors":
      return "3";
    case "Four Floors":
      return "4";
    case "Five Floors":
      return "5";
  }

  return undefined;
}

/**
 * Converts the FE pricing duration to BE
 *
 * @param {string | undefined} pricingDuration - the frontend (legacy) pricing duration
 * @returns {string | undefined}
 */
export function convertPricingDurationToBackend(pricingDuration?: string): string | undefined {
  if (!pricingDuration) {
    return undefined;
  }

  switch (pricingDuration) {
    case PriceDurationFE.PerDay:
      return PriceDurationBE.PerDay;
    case PriceDurationFE.PerHalfDay:
      return PriceDurationBE.PerHalfDay;
    case PriceDurationFE.PerHour:
      return PriceDurationBE.PerHour;
    case PriceDurationFE.PerPerson:
      return PriceDurationBE.PerPerson;
  }

  return undefined;
}

/**
 * Returns the number of Room in Listing item
 *
 * @param {SearchType.SearchItem | undefined} listing - the Listing item
 * @returns {number}
 */
export function getRoomsNumber(listing?: SearchType.SearchItem): number {
  let roomsNumber: number;
  if (Array.isArray(listing?.rooms)) {
    roomsNumber = listing?.rooms?.length ?? 0;
  } else {
    roomsNumber = (listing?.data as VenueType.VenueItem)?.rooms?.length ?? 0;
  }
  return roomsNumber;
}

export function isRoomValidForPublish(room: Partial<SearchType.SearchRoom>): boolean {
  const isRequiredFields = Boolean(
    room.name &&
      room.description &&
      room.images?.length &&
      room.maxCapacity &&
      room.sqFootage &&
      room.numberOfFloors,
  );
  const isPriceValid = Boolean(
    room.showPricing ? room.pricingDuration && room.pricingValueInCents : true,
  );
  return isRequiredFields && isPriceValid;
}

/**
 * Returns does SearchItem supports V2 files
 *
 * @param {SearchType.SearchItem | undefined} listing - the Listing item
 * @returns {boolean}
 */
export function supportV2Files(listing?: SearchType.SearchItem): boolean {
  return Array.isArray(listing?.files);
}

/**
 * Returns the URL for the SearchFile
 *
 * @param {SearchType.SearchFile | undefined} searchFile - the Listing file item
 * @returns {boolean}
 */
export function buildFileUrl(searchFile?: SearchType.SearchFile): string | undefined {
  if (!searchFile || (!searchFile.url && !searchFile.blobName)) {
    return undefined;
  }

  if (searchFile.url) {
    return searchFile.url;
  }

  return `${SharedConfigManager.getValue(KEYS.TEXT_CDN_URL)}/assets/${searchFile.blobName}`;
}

export function isSearchAvailableForInquiries(
  searchItem?: SearchType.Search,
  currentUser?: AuthType.LoginResponseProfile,
): boolean {
  if (currentUser?.is_admin) {
    return true; // internal users testing (impersonate mode)
  }

  if (searchItem?.isActive && ListingStatus.PUBLISHED === searchItem?.status) {
    return true;
  }

  return false;
}

export function isSearchActive(searchItem?: SearchType.Search): boolean {
  return !!searchItem?.isActive && ListingStatus.PUBLISHED === searchItem?.status;
}

/**
 * Check if listing has legacy data
 *
 * @param {SearchType.SearchItem} listingData
 * @returns
 */
export function hasOnlyLegacyData(listingData?: SearchType.SearchItem): boolean {
  return !!listingData?.data && !Array.isArray(listingData?.contacts);
}

export function trackFilterUsed(val: any, dataKey: string, locationInApp: string) {
  ActivityLogic.ga4Tracking("search", {
    search_term: `${val}`,
    seatch_term_type: `${dataKey}`,
  });
  ActivityLogic.facebookTracking("track", "Search");
  ActivityLogic.toActivityService({
    action: "filter",
    actionId: `${val}`,
    actionIdType: "filterValue",
    locationInApp,
    data1Type: "filterType",
    data1Value: `${dataKey}`,
    data2Type: "value",
    data2Value: `${val}`,
  });
}

export function countAppliedFilters(
  listingType: SearchTypes,
  getFilterData?: <T>(property: string) => T,
) {
  const extraFilters =
    listingType === SearchTypes.Venue
      ? VENUE_LISTINGS_EXTRA_FILTERS
      : VENDOR_LISTINGS_EXTRA_FILTERS;
  return extraFilters.reduce((totalFiltersCount, filterKey) => {
    const filterValues = getFilterData && getFilterData(filterKey);
    if (filterValues) {
      const valuesArray = Array.isArray(filterValues) ? filterValues : [filterValues];
      return totalFiltersCount + valuesArray.length;
    }
    return totalFiltersCount;
  }, 0);
}
